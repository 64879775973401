import iconEstoque from "../assets/icon_estoque.png";
import iconVendas from "../assets/icon_vendas.png";
import iconConectividade from "../assets/icon_conectividade.png";
import iconHoras from "../assets/icon_horas.png";
import iconUsabilidade from "../assets/icon_usabilidade.png";
import iconFacilidade from "../assets/icon_facilidade.png";

export const infoCards = [
  {
    id: 1,
    icon: iconEstoque,
    title: "Controle de Estoque",
    description:
      "Gerencie o estoque remotamente incluindo e excluindo itens necessários.",
  },
  {
    id: 2,
    icon: iconVendas,
    title: "Acompanhamento de vendas",
    description:
      "Acompanhe em real time o desempenho das vendas do seu micromarket autônomo.",
  },
  {
    id: 3,
    icon: iconConectividade,
    title: "Conectividade das maquinas",
    description:
      "Confira a conectividade das maquinas do seu PDV com a telemetria PayLabs.",
  },
  {
    id: 4,
    icon: iconHoras,
    title: "Venda 24h por dia 7 dias por semana",
    description:
      "Venda em qualquer oportunidade sem a necessidade de presença de vendedores.",
  },
  {
    id: 5,
    icon: iconUsabilidade,
    title: "Usabilidade descomplicada",
    description:
      "Sistema e aplicativos pensados e desenvolvidos para facilitar a experiência do usuário.",
  },
  {
    id: 6,
    icon: iconFacilidade,
    title: "Facilidade de instalação",
    description:
      "Solução simplificada e fácil de instalação da maquina para o seu PDV autônomo.",
  },
];
