import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputMask from "react-input-mask";

import * as api from '../../services/api'

import "./styles.css";

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup
      .string()
      .test(
        "empty-check",
        "Regex validation",
        (phone) =>
          !phone ||
          /^(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/.test(phone)
      )
      .required(),
    message: yup.string().required(),
  })
  .required();

//

export function Form() {

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmit = (data) => {
    api.post(data.name, data.email, data.phone, data.message)
  }

  return (
    <div
      className="d-flex align-items-center justify-content-center py-5 bg-cyan w-100"
      style={{ maxWidth: "unset" }}
      id="formSubscribe"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="py-5 col-12 d-flex flex-column align-items-center justify-content-center"
      >
        <h2 className="text-white text-bold">Quero conhecer</h2>
        <div className="d-flex align-items-center justify-content-center col-11 input-wrapper mt-4">
          <input
            placeholder="Nome"
            {...register("name")}
            className={`${errors.name ? "invalid" : ""} col-12 color-gray`}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center col-11 input-wrapper mt-3">
          <input
            placeholder="E-mail"
            {...register("email")}
            className={`${errors.email ? "invalid" : ""} col-12 color-gray`}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center col-11 input-wrapper mt-3">
          <Controller
            name="phone"
            control={control}
            render={({ field }) => {
              return (
                <InputMask
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  mask="(99) 99999-9999"
                  className={`${
                    errors.phone ? "invalid" : ""
                  } col-12 color-gray`}
                  placeholder="Celular"
                />
              );
            }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center col-11 input-wrapper mb-4 mt-3">
          <textarea
            placeholder="Mensagem"
            {...register("message")}
            className={`${errors.message ? "invalid" : ""} col-12 color-gray`}
          />
        </div>
        <button type="submit" className="col-6">
          ENVIAR
        </button>
      </form>
    </div>
  );
}