import React from "react";

import "./styles.css"

export function InfoCard({
    icon,
    title,
    description,
}) {
    return (
        <div className="d-flex flex-row align-items-start justify-content-center col-11 infocard">
            <img src={icon} alt={`Ícone ${title}`} />
            <div className="d-flex flex-column align-items-start justify-content-start">
                <h4 className="text-bold">{title}</h4>
                <p>{description}</p>
            </div>
        </div>
    );
}