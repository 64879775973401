import axios from "axios";
import Swal from "sweetalert2";

const url = 'https://api2.paylabs.app/lp-send-mail'

export const post = (name, email, celphone, message) => {
  axios.post(url, {
    name,
    email,
    celphone,
    message
  }).then(res => {
    console.log(res)
    Swal.fire({
      title: "Mensagem enviada",
      icon: "success",
      text: "Sua mensagem foi enviada com sucesso!",
    }).then(() => {
      window.location.reload();
    })
  }).catch(err => {
    console.log(err)
    Swal.fire({
      title: "Oops...",
      icon: "error",
      confirmButtonText: 'OK',
      text: `Preencha todos os campos corretamente.`,
    })
  })
}
