import React from "react";

import imgLogo from "../../assets/logo.png";
import imgFacebook from "../../assets/facebook.png";
import imgLinkedin from "../../assets/linkedin.png";
import imgInstagram from "../../assets/instagram.png";

import "./styles.css";

export function Footer() {
    return (
        <footer style={{ maxWidth: "unset" }} className="w-100">
            <div className="d-flex align-items-center justify-content-center w-100 bg-purple-dark p-5">
                <div className="footer-content col-12 d-flex align-items-center justify-content-center justify-content-between flex-column flex-md-row">
                    <img src={imgLogo} alt="Logo PayLabs" className="col-5 mb-4 mb-md-0 logo"/>
                    <div className="social-media d-flex flex-row flex-wrap align-items-center justify-content-around col-12">
                        <p className="text-white text-bold m-0">Siga-nos</p>
                        <div className="d-flex flex-row align-items-center justify-content-between col-6">
                            <a href="https://facebook.com/" target="_blank" rel="noreferrer" className="col-3 d-flex">
                                <img className="w-100" src={imgFacebook} alt="Ícone " />
                            </a>
                            <a href="https://instagram.com/" target="_blank" rel="noreferrer" className="col-3 d-flex">
                                <img className="w-100" src={imgInstagram} alt="Ícone " />
                            </a>
                            <a href="https://linkedin.com/" target="_blank" rel="noreferrer" className="col-3 d-flex">
                                <img className="w-100" src={imgLinkedin} alt="Ícone " />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center py-3 w-100 bg-purple-darker">
                <p className="text-white m-0 text-center">Copyright © 2022 PayLabs - Todos os direitos reservados.</p>
            </div>
        </footer>
    );
}