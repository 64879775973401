import React from "react";

import "./styles.css";

import imgLogo from "../../assets/logo.png";
import imgWhatsapp from "../../assets/whatsapp.png";

export function Header() {
  return (
    <div className="d-flex align-items-center justify-content-center col-12 col-md-10">
      <header className="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between col-10 col-md-12 pt-5 pb-3 py-md-5">
        <img src={imgLogo} alt="Logo paylabs" className="col-8 logo" />
        <div className="d-flex align-items-center justify-content-center justify-content-md-end col-8 mt-3 mt-md-0">
          <img src={imgWhatsapp} alt="Ícone Whatsapp" className="wpp" />
          <a href="https://wa.me/5511991061052" className="text-decoration-none" target="_blank" rel="noreferrer"><p className="text-white">11 99106.1052</p></a>
        </div>
      </header>
    </div>
  );
}
