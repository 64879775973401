/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { InfoCard } from "./components/InfoCard";
import { Form } from "./components/Form";

import { infoCards } from "./utils/infoCards";

import "./styles/global.css";

import imgHero from "./assets/hero.png";
import imgElipse from "./assets/elipse.png";
import imgPhone from "./assets/figure.png";
import imgDashobard from "./assets/dashboard.png";
import imgWpp from './assets/whatsapp.png'

function App() {
  return (
    <div className="App">
      <a href="https://wa.me/5511991061052" target="_blank" rel="noreferrer" className="wpp-button position-absolute bottom-0 end-0 translate-middle rounded-circle p-4 d-flex justify-content-center" style={{zIndex: '3'}}>
        <img src={imgWpp} alt="WhatsApp"/>
      </a>
      <Header />
      <div className="hero d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start col-12 col-md-10">
        <div className="d-flex flex-column align-items-center align-items-md-start col-11 col-md-5 pb-5 pb-md-0">
          <h1 className="col-12 text-white">Seu micromarket descomplicado</h1>
          <p>
            Comece seu micromarket autônomo com a praticidade e eficiência do
            sistema PayLabs.
          </p>
          
          <a href="#formSubscribe" className="text-decoration-none text-white col-10" type="button">
            QUERO CONHECER</a>
        </div>
        <img className="hero col-12 col-md-7" src={imgHero} alt="Hero" />
      </div>

      <div className="content-container d-flex flex-column align-items-center justify-content-ctart col-12 pb-5 pt-4 mt-5">
        <img className="elipse" alt="Elipse" src={imgElipse} />
        <div className="col-11 mb-5 d-flex justify-content-center">
          <iframe className="i-video" height="" src="https://www.youtube.com/embed/JM-tLTYWAy4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div className="rounded-card col-11 py-5 px-4 shadow rounded d-flex flex-column align-items-center justify-content-center">
          <h4 className="col-11 m-0 color-purple text-center text-bold">
            A tecnologia ideal para seu micromarket residencial ou comercial.
          </h4>
          <p className="col-11 col-md-8 m-0 text-center color-gray">
            Tenha toda a solução necessária para seu micromarket e ponto de
            venda autônomo com o sistema e aplicativos PayLabs.
          </p>
        </div>
        <div className="infocards-container d-flex flex-row align-items-start justify-content-center flex-wrap col-12 col-md-10">
          {infoCards.map((item) => {
            return <InfoCard key={item.id} {...item} />;
          })}
        </div>
        <div className="purple-container col-11 col-md-10 d-flex flex-row flex-wrap align-items-center justify-content-center justify-content-md-evenly rounded bg-purple p-4 px-md-3 py-md-5">
          <img
            src={imgPhone}
            alt="Mulher com um celular ilustração"
            className="col-12 col-md-5"
          />
          <div className="d-flex flex-column align-items-start justify-content-start col-12 col-md-6 pt-5 pt-md-0">
            <h2 className="color-purple-light text-bold mb-2">Aplicativos</h2>
            <h3 className="mb-0 text-white text-bold">Aplicativo consumidor</h3>
            <p className="color-purple-light">
              Seus consumidores poderão consultar os produtos disponíveis a
              distância, desbloquear compras de itens com limite de idade e ter
              acesso a informações de como comprar.
            </p>
            <h3 className="mb-0 text-white text-bold">Aplicativo lojista</h3>
            <p className="color-purple-light m-0">
              Seus lojistas poderão de forma remota gerenciar, conferir as
              vendas realizadas, ver a telemetria dos PDVs e abastecer de forma
              simplificada e eficaz a loja.
            </p>
          </div>
        </div>
        <div className="dashboard-container col-11 col-md-10 d-flex flex-column flex-md-row-reverse align-items-center justify-content-between pt-5 my-5">
          <img src={imgDashobard} className="col-12 col-md-8" />
          <div className="d-flex flex-column align-items-start justify-content-center col-12 col-md-4 mt-4 m-md-0">
            <h2
              className="color-purple text-bold"
              style={{ fontSize: "2.5rem" }}
            >
              Painel
              <br />
              administrativo
            </h2>
            <ul className="p-3 pt-0">
              <li className="color-gray">Acompanhe suas vendas</li>
              <li className="color-gray">Confira a telemetria das maquinas</li>
              <li className="color-gray">Veja as transações realizadas</li>
              <li className="color-gray">Gerencie seu estoque</li>
              <li className="color-gray">
                Envie notificações aos consumidores
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Form />
      <Footer />
    </div>
  );
}

export default App;
